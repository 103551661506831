import { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  SETUSERDATAFROMSETTINGS,
} from "store/reducers/actions";
import authReducer from "store/reducers/auth";
import axios from "utils/axios";
import Loader from "../components/Loader";

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const verifyToken = (token) => {
  if (!token) {
    return false;
  }
  const decoded = jwtDecode(token);
  return decoded.exp > Date.now() / 1000;
};

const refreshToken = async (refresh) => {
  const response = await axios.post("/users/token/refresh/", {
    refresh,
  });
  const tokens = response.data;
  setSession(tokens);
  return tokens.access;
};

const setSession = (tokens) => {
  if (tokens) {
    localStorage.setItem("accessToken", tokens.access);
    localStorage.setItem("refreshToken", tokens.refresh);
    axios.defaults.headers.common.Authorization = `Bearer ${tokens.access}`;
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const clearImpersonationMode = () => {
  localStorage.removeItem("impersonateUserId");
  localStorage.removeItem("impersonate_user_access_token");
  localStorage.removeItem("impersonate_user_refresh_token");
};

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const setUserLoggedIn = (token) => {
    const { first_name, last_name, email, is_superuser, user_id } =
      jwtDecode(token);
    const user = {
      first_name,
      last_name,
      email,
      is_superuser,
      user_id,
    };

    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user,
      },
    });
  };

  const setUserFromSettings = (data) => {
    dispatch({
      type: SETUSERDATAFROMSETTINGS,
      payload: {
        user: data,
      },
    });
  };

  useEffect(() => {
    const init = async () => {
      try {
        const access_token = window.localStorage.getItem("accessToken");
        if (access_token && verifyToken(access_token)) {
          setUserLoggedIn(access_token);
        } else {
          const refresh_token = window.localStorage.getItem("refreshToken");
          if (refresh_token && verifyToken(refresh_token)) {
            const access_token = await refreshToken(refresh_token);
            setUserLoggedIn(access_token);
          } else {
            dispatch({
              type: LOGOUT,
            });
          }
        }
      } catch (err) {
        console.error(err);
        setSession(null);
        clearImpersonationMode();
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const signin = async ({ email, password }) => {
    const response = await axios.post("/users/token/obtain/", {
      email,
      password,
    });

    const tokens = response.data;
    setSession(tokens);
    setUserLoggedIn(tokens.access);
  };

  const signup = async ({ email, password, first_name, last_name }) => {
    const response = await axios.post("/users/create/", {
      email,
      password,
      first_name,
      last_name,
    });

    let user = response.data;
    dispatch({
      type: REGISTER,
      payload: { user },
    });
  };

  const signout = () => {
    setSession(null);
    clearImpersonationMode();
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async () => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        signin,
        signout,
        signup,
        resetPassword,
        updateProfile,
        setSession,
        setUserLoggedIn,
        setUserFromSettings,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

// JWTProvider.propTypes = {
//   children: PropTypes.node
// };

export default JWTContext;
