import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.scss";

import App from "./App";
import { store, persister } from "store";
import { AppProvider } from "context/AppContext";

Sentry.init({
  dsn: "https://f5c9210ef77a4c48b586aed99f92a500@o4504260732190720.ingest.sentry.io/4504543031721984",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <AppProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppProvider>
    </PersistGate>
  </ReduxProvider>
  // </React.StrictMode>
);
