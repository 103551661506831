import { lazy } from "react";
import GuestGuard from "utils/GuestGuard";
import Loadable from "components/Loadable";

const SignIn = Loadable(lazy(() => import("pages/auth/SignIn")));
const SignUp = Loadable(lazy(() => import("pages/auth/SignUp")));
const VerifyToken = Loadable(lazy(() => import("pages/auth/VerifyToken")));

const AuthRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <GuestGuard />,
      children: [
        {
          path: "signin",
          element: <SignIn />,
        },
        {
          path: "signup",
          element: <SignUp />,
        },
        {
          path: "email-verify",
          index: true,
          element: <VerifyToken />,
        },
      ],
    },
  ],
};

export default AuthRoutes;
