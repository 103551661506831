import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import accountReducer from "./account";
import leadReducer from "./lead";
import campaignReducer from "./campaign";
import snackbarReducer from "./snackbar";

const reducers = combineReducers({
  account: persistReducer(
    {
      key: "account",
      storage,
    },
    accountReducer
  ),
  lead: persistReducer(
    {
      key: "lead",
      storage,
    },
    leadReducer
  ),
  campaign: persistReducer(
    {
      key: "campaign",
      storage,
    },
    campaignReducer
  ),
  snackbar: snackbarReducer,
});

export default reducers;
