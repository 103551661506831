import { createSlice } from "@reduxjs/toolkit";

import axios from "utils/axios";
import { dispatch } from "../index";

const initialState = {
  error: null,
  campaigns: [],
  campaign: {},
  steps: [],
  leads: [],
  analytics: {},
};

const slice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },

    getCampaignsSuccess(state, action) {
      state.campaigns = action.payload;
    },

    getCampaignSuccess(state, action) {
      state.campaign = action.payload;
    },

    updateCampaignLeadData(state, action) {
      state.leads = action.payload;
    },

    getStepsSuccess(state, action) {
      state.steps = action.payload;
    },
    getLeadsSuccess(state, action) {
      state.leads = action.payload;
    },
    getMoreLeadsSuccess(state, action) {
      let payload = {
        ...action.payload,
        results: [...state.leads.results, ...action.payload.results],
      };
      state.leads = payload;
    },
    getAnalyticsSuccess(state, action) {
      state.analytics = action.payload;
    },
  },
});

export default slice.reducer;

export function getCampaigns() {
  return async () => {
    try {
      const response = await axios.get("/campaign/");
      dispatch(slice.actions.getCampaignsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCampaign(id) {
  return async () => {
    try {
      const response = await axios.get(`/campaign/${id}/`);
      dispatch(slice.actions.getCampaignSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateLeadsAfterDeletion(leads) {
  dispatch(slice.actions.updateCampaignLeadData(leads));
}

export function getSteps(id) {
  return async () => {
    try {
      const response = await axios.get(`/campaign/steps/${id}/`);
      dispatch(slice.actions.getStepsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLeads(id) {
  return async () => {
    try {
      const response = await axios.get(`/campaign/leads/${id}/`);
      dispatch(slice.actions.getLeadsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMoreLeads(url) {
  return async () => {
    let paramString = url.split("?")[1];
    let queryString = new URLSearchParams(paramString);

    let pageLength = queryString.get("l");
    let pageIndex = queryString.get("o");
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getMoreLeadsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAnalytics(id) {
  return async () => {
    try {
      const response = await axios.get(`/campaign/analytics/${id}/`);
      dispatch(slice.actions.getAnalyticsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
