import { createSlice } from "@reduxjs/toolkit";

import axios from "utils/axios";
import { dispatch } from "../index";

const initialState = {
  error: null,
  leads: [],
  lead: null,
  lead_values: [],
};

const slice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },

    getLeadsSuccess(state, action) {
      state.leads = action.payload;
    },

    getMoreLeadsSuccess(state, action) {
      let payload = {
        ...action.payload,
        results: [...state.leads.results, ...action.payload.results],
      };
      state.leads = payload;
    },

    getLeadSuccess(state, action) {
      state.lead = action.payload;
    },

    getLeadValues(state, action) {
      state.lead_values = action.payload;
    },
    clearLeadValues(state, action) {
      state.lead_values = [];
    },
  },
});

export default slice.reducer;

export function getLeads() {
  return async () => {
    try {
      const response = await axios.get("/lead/");
      dispatch(slice.actions.getLeadsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMoreLeads(url) {
  return async () => {
    let paramString = url.split("?")[1];
    let queryString = new URLSearchParams(paramString);

    let pageLength = queryString.get("l");
    let pageIndex = queryString.get("o");
    try {
      const response = await axios.get(`/lead/?l=${pageLength}&o=${pageIndex}`);
      dispatch(slice.actions.getMoreLeadsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLead(id) {
  return async () => {
    try {
      const response = await axios.get(`/lead/{${id}}/`);
      dispatch(slice.actions.getLeadSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLeadValues(id) {
  return async () => {
    try {
      let endpoint = `/campaign/custom_var/${id}/`;
      const response = await axios.get(endpoint);
      console.log("RESPONSE : ", response);
    } catch (error) {
      console.log("ERROR : ", error);
    }
  };
}
