import React from "react";
import Routes from "routes";
import { JWTProvider as AuthProvider } from "context/JWTContext";

import Snackbar from "components/Snackbar";
import StickyNavBar from "components/StickyNavBar";

function App() {
  return (
    <AuthProvider>
      <Snackbar />
      <StickyNavBar />
      <Routes />
    </AuthProvider>
  );
}

export default App;
