import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Spinner, Dropdown } from 'react-bootstrap';
import { IconDown } from 'icons/IconTheme';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Navbar from 'react-bootstrap/Navbar';

import axios from 'utils/axios';
import useAuth from 'context/useAuth';

export default function StickyNavBar() {
  const { user, isLoggedIn } = useAuth();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const getUsers = async () => {
    try {
      let response = await axios.get(`/users/admin-get-all-users/`);
      console.log('response', response.data);
      setUsers(response.data);
      const selectedUserId = localStorage.getItem('impersonateUserId');
      if (selectedUserId) {
        const userData = response.data.find((x) => x.id === selectedUserId);
        setSelectedUser(userData);
      }
    } catch (error) {
      let message = '';
      if (error.message) {
        message = error.message;
      } else {
        message = 'Unexpected Error!';
      }
    }
  };

  const getUserToken = async (id) => {
    setLoading(true);
    try {
      localStorage.setItem('impersonateUserId', id);

      let response = await axios.get(
        `/users/admin-get-spoof-user-token/${id}/`
      );
      console.log('response', response.data);
      localStorage.setItem(
        'impersonate_user_access_token',
        response.data.access
      );
      localStorage.setItem(
        'impersonate_user_refresh_token',
        response.data.refresh
      );
      setLoading(false);
      window.location.reload();
    } catch (error) {
      setLoading(false);
      let message = '';
      if (error.message) {
        message = error.message;
      } else {
        message = 'Unexpected Error!';
      }
    }
  };

  const onSelectUser = async (user) => {
    await getUserToken(user.id);
    setSelectedUser(user);
  };

  useEffect(() => {
    console.log('user', user);
    const impersonateUserId = localStorage.getItem('impersonateUserId');
    if (user?.is_superuser) {
      getUsers();
    }
  }, [user]);

  if (!user?.is_superuser || !isLoggedIn) {
    return null;
  }

  return (
    <Navbar bg="success" variant="dark" sticky="top">
      <Container style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <span style={{ margin: '0 auto', color: '#fff' }}>Admin</span>
        </div>
        {users.length > 0 && (
          <Dropdown as={ButtonGroup}>
            <Button variant="success">
              {selectedUser ? selectedUser.email : 'Select user'}
            </Button>

            <Dropdown.Toggle
              split
              variant="success"
              id="dropdown-split-basic"
              disabled={loading}
            />

            <Dropdown.Menu style={{ overflowY: 'scroll', height: 400 }}>
              {users.map((user) => (
                <Dropdown.Item onClick={() => onSelectUser(user)}>
                  {user.email}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
        {loading && (
          <Spinner
            style={{ marginLeft: 10, color: '#fff' }}
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </Container>
    </Navbar>
  );
}
