import axios from "axios";

const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

axiosServices.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("accessToken");
    let impersonate_user_access_token = localStorage.getItem(
      "impersonate_user_access_token"
    );
    let impersonate_user_id = localStorage.getItem("impersonateUserId");

    if (impersonate_user_id) {
      token = impersonate_user_access_token;
    }

    if (
      config.url === "/users/admin-get-all-users/" ||
      config.url === `/users/admin-get-spoof-user-token/${impersonate_user_id}/`
    ) {
      token = localStorage.getItem("accessToken");
    }

    if (token) {
      const contentType =
        config.data instanceof FormData
          ? "x-www-form-urlencoded"
          : "application/json";

      config.headers["Content-Type"] = contentType;
      config.headers["Authorization"] = `JWT ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosServices.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;

    if (originalConfig.url !== "/users/token/obtain/" && error.response) {
      // Access Token was expired
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        let refreshToken = localStorage.getItem("refreshToken");
        let impersonate_user_id = localStorage.getItem("impersonateUserId");
        let impersonate_user_refresh_token = localStorage.getItem(
          "impersonate_user_refresh_token"
        );

        if (impersonate_user_id && impersonate_user_refresh_token) {
          refreshToken = impersonate_user_refresh_token;
        }

        if (
          originalConfig.url === "/users/admin-get-all-users/" ||
          originalConfig.url ===
            `/users/admin-get-spoof-user-token/${impersonate_user_id}/`
        ) {
          refreshToken = localStorage.getItem("refreshToken");
        }

        try {
          const res = await axiosServices.post(`/users/token/refresh/`, {
            refresh: refreshToken,
          });

          let access = res.data.access;
          let refresh = res.data.refresh;

          if (impersonate_user_id && impersonate_user_refresh_token) {
            if (
              originalConfig.url === "/users/admin-get-all-users/" ||
              originalConfig.url ===
                `/users/admin-get-spoof-user-token/${impersonate_user_id}/`
            ) {
              localStorage.setItem("accessToken", access);
              localStorage.setItem("refreshToken", refresh);
            } else {
              localStorage.setItem("impersonate_user_access_token", access);
              localStorage.setItem("impersonate_user_refresh_token", refresh);
            }
          } else {
            localStorage.setItem("accessToken", access);
            localStorage.setItem("refreshToken", refresh);
          }

          return axiosServices(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(
      (error.response && error.response.data) || "Wrong Services"
    );
  }
);

export default axiosServices;
