import { createSlice } from '@reduxjs/toolkit';

import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
  error: null,
  accounts: [],
  userInfo: {},
  account: null,
  logs: { count: 0, next: '', previous: '', results: [] },
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },

    getAccountsSuccess(state, action) {
      state.accounts = action.payload;
    },

    getUserInfoSuccess(state, action) {
      state.userInfo = action.payload;
    },

    getAccountSuccess(state, action) {
      state.account = action.payload;
    },

    getLogsSuccess(state, action) {
      state.logs = action.payload;
    },

    getNextLogsSuccess(state, action) {
      let payload = {
        ...action.payload,
        results: [...state.logs.results, ...action.payload.results],
      };
      state.logs = payload;
    },
  },
});

export default slice.reducer;

export function getAccounts() {
  return async () => {
    try {
      const response = await axios.get('/instagram/accounts/');
      dispatch(slice.actions.getAccountsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserInfo() {
  return async () => {
    try {
      const response = await axios.get('users/user-info/');
      dispatch(slice.actions.getUserInfoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAccount(id) {
  return async () => {
    try {
      const response = await axios.get(`/instagram/accounts/{${id}}/`);
      dispatch(slice.actions.getAccountSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLogs(id, pageLength, pageIndex) {
  return async () => {
    try {
      const response = await axios.get(
        `/instagram/get-logs/${id}/?l=${pageLength}&o=${pageIndex}`
      );
      dispatch(slice.actions.getLogsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNextLogs(id, url) {
  return async () => {
    let paramString = url.split('?')[1];
    let queryString = new URLSearchParams(paramString);

    let pageLength = queryString.get('l');
    let pageIndex = queryString.get('o');

    try {
      const response = await axios.get(
        `/instagram/get-logs/${id}/?l=${pageLength}&o=${pageIndex}`
      );
      dispatch(slice.actions.getNextLogsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
