import { lazy } from "react";
import { useRoutes } from "react-router-dom";
import Loadable from "components/Loadable";

import AuthRoutes from "./AuthRoutes";
import MainRoutes from "./MainRoutes";

const SignIn = Loadable(lazy(() => import("pages/auth/SignIn")));

export default function Routes() {
  return useRoutes([
    {
      path: "/",
      element: <SignIn />,
    },
    AuthRoutes,
    MainRoutes,
  ]);
}
