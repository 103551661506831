// action - account reducer
export const LOGIN = '@auth/LOGIN';
export const LOGOUT = '@auth/LOGOUT';
export const REGISTER = '@auth/REGISTER';
export const SETUSERDATAFROMSETTINGS = '@auth/SETUSERDATAFROMSETTINGS';

export const TOGGLE_SNACKBAR_OPEN = 'TOGGLE_SNACKBAR_OPEN';
export const TOGGLE_SNACKBAR_CLOSE = 'TOGGLE_SNACKBAR_CLOSE';

export const toggleSnackbarOpen = (payload) => ({
  type: TOGGLE_SNACKBAR_OPEN,
  payload: payload,
});

export const toggleSnackbarClose = () => ({
  type: TOGGLE_SNACKBAR_CLOSE,
});
