import { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import useAuth from "context/useAuth";

const AuthGuard = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/signin", { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return <Outlet />;
};

export default AuthGuard;
