import { lazy } from "react";
import AuthGuard from "utils/AuthGuard";
import Loadable from "components/Loadable";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
  "pk_live_51LOD0m2E8BIv7fejVPpUUsW9OqTOoqVHfYqARPu8GxHZ2OoCoJ1kN1C0ZhfsaqhxxTrzkbPremcmGMCdYwrBkH7S00zpcfvzEy"
);

const Inbox = Loadable(lazy(() => import("pages/inbox/Inbox")));
const Accounts = Loadable(lazy(() => import("pages/accounts/Accounts")));
const Analytics = Loadable(lazy(() => import("pages/analytics/Analytics")));
const AddNewCampaign = Loadable(
  lazy(() => import("pages/campaigns/AddNewCampaign"))
);
const CampaignAnalytics = Loadable(
  lazy(() => import("pages/campaigns/CampaignAnalytics"))
);
const CampaignLead = Loadable(
  lazy(() => import("pages/campaigns/CampaignLead"))
);
const CampaignList = Loadable(
  lazy(() => import("pages/campaigns/CampaignList"))
);
const CampaignOption = Loadable(
  lazy(() => import("pages/campaigns/CampaignOption"))
);
const CampaignSchedule = Loadable(
  lazy(() => import("pages/campaigns/CampaignSchedule"))
);
const CampaignSequence = Loadable(
  lazy(() => import("pages/campaigns/CampaignSequence"))
);
const AddCsv = Loadable(lazy(() => import("pages/lead-lists/AddCsv")));
const AddGoogle = Loadable(lazy(() => import("pages/lead-lists/AddGoogle")));
const AddGoogleCriteria = Loadable(
  lazy(() => import("pages/lead-lists/AddGoogleCriteria"))
);
const AddGoogleCriteriaTwo = Loadable(
  lazy(() => import("pages/lead-lists/AddGoogleCriteriaTwo"))
);
const AddInstagram = Loadable(
  lazy(() => import("pages/lead-lists/AddInstagram"))
);
const AddInstagramCriteria = Loadable(
  lazy(() => import("pages/lead-lists/AddInstagramCriteria"))
);
const AddManual = Loadable(lazy(() => import("pages/lead-lists/AddManual")));
const AddNewLead = Loadable(lazy(() => import("pages/lead-lists/AddNewLead")));
const CreateLead = Loadable(lazy(() => import("pages/lead-lists/CreateLead")));
const LeadList = Loadable(lazy(() => import("pages/lead-lists/LeadList")));
const SelectLeadSource = Loadable(
  lazy(() => import("pages/lead-lists/SelectLeadSource"))
);
const SettingPlan = Loadable(lazy(() => import("pages/setting/SettingPlan")));
const SettingBilling = Loadable(
  lazy(() => import("pages/setting/SettingBilling"))
);
const SettingProfile = Loadable(
  lazy(() => import("pages/setting/SettingProfile"))
);

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <AuthGuard />,
      children: [
        {
          path: "inbox/:userName/:id",
          element: <Inbox />,
        },
        {
          path: "analytics",
          element: <Analytics />,
        },
        {
          path: "account",
          element: <Accounts />,
        },
        {
          path: "setting",
          children: [
            {
              path: "plans",
              element: (
                <Elements stripe={stripePromise}>
                  <SettingPlan />
                </Elements>
              ),
            },
            {
              path: "billing",
              element: (
                <Elements stripe={stripePromise}>
                  <SettingBilling />
                </Elements>
              ),
            },
            {
              path: "profile",
              element: <SettingProfile />,
            },
          ],
        },
        {
          path: "leadlist",
          children: [
            {
              index: true,
              element: <LeadList />,
            },
            {
              path: "add",
              element: <AddNewLead />,
            },
            {
              path: "create",
              element: <CreateLead />,
            },
            {
              path: "source",
              element: <SelectLeadSource />,
            },
            {
              path: "add-mannual",
              element: <AddManual />,
            },
            {
              path: "add-csv",
              element: <AddCsv />,
            },
            {
              path: "add-google",
              element: <AddGoogle />,
            },
            {
              path: "add-google-criteria",
              element: <AddGoogleCriteria />,
            },
            {
              path: "add-google-criteria-two",
              element: <AddGoogleCriteriaTwo />,
            },
            {
              path: "add-instagram",
              element: <AddInstagram />,
            },
            {
              path: "add-instagram-criteria",
              element: <AddInstagramCriteria />,
            },
          ],
        },
        {
          path: "campaign",
          children: [
            {
              index: true,
              element: <CampaignList />,
            },
            {
              path: "add",
              element: <AddNewCampaign />,
            },
            {
              path: ":id",
              children: [
                {
                  index: true,
                  element: <CampaignAnalytics />,
                },
                {
                  path: "analytics",
                  element: <CampaignAnalytics />,
                },
                {
                  path: "lead",
                  element: <CampaignLead />,
                },
                {
                  path: "sequence",
                  element: <CampaignSequence />,
                },
                {
                  path: "schedule",
                  element: <CampaignSchedule />,
                },
                {
                  path: "option",
                  element: <CampaignOption />,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default MainRoutes;
