import { TOGGLE_SNACKBAR_OPEN, TOGGLE_SNACKBAR_CLOSE } from "./actions";

const initialState = {
  open: false,
  message: "",
  severity: "success",
};

const snackbar = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SNACKBAR_OPEN: {
      return {
        ...state,
        open: true,
        message: action.payload.message,
        severity: action.payload.severity,
      };
    }

    case TOGGLE_SNACKBAR_CLOSE: {
      return {
        ...state,
        open: false,
        message: "",
        severity: "success",
      };
    }

    default: {
      return state;
    }
  }
};

export default snackbar;
